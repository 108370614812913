<template>
  <svg
    id="ec8847c7-9bd6-4723-89f0-293d2d28b81f"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.37,0H29.63A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.63,37H7.38A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M27.32,30.28H24.76V20.07a.49.49,0,0,0-.49-.49V12.32a.48.48,0,0,0-.48-.49V6.45A.49.49,0,0
        ,0,23.3,6H18.93V4.5a.49.49,0,1,0-1,0V6H13.58a.49.49,0,0,0-.49.49v5.38a.49.49,0,0
        ,0-.49.49v7.27a.49.49,0,0,0-.49.49V30.28H9.69a.49.49,0,1,0,0,1H27.32a.49.49,0,0,0
        ,0-1ZM14.07,6.93h8.75v4.9H14.07Zm-.49,5.87H23.3v6.79H13.58ZM18,30.28H16V26.43H18Zm2.92
        ,0H18.93V26.43h1.94Zm2.92,0H21.84V25.94a.49.49,0,0,0-.49-.49H15.52a.49.49,0,0,0-.49.49v4.34H13.09V20.56H23.79Z"
        transform="translate(0 -0.03)"
        fill="#fff" /><path
          d="M15,24.46h6.8a.49.49,0,0,0,.49-.49V22a.49.49,0,0,0-.49-.49H15a.49.49,0,0,0-.49.49V24A.49.49,0,0,0,15,24.46Zm.49-1.94h5.83v1H15.52Z"
          transform="translate(0 -0.03)"
          fill="#fff" /><path
            d="M21.86,13.79h-6.8a.49.49,0,0,0-.49.49v4a.49.49,0,0,0,.49.49h6.8a.49.49,0,0,0,.49-.49v-4A.49.49,0,0,0,21.86,13.79Zm-.49,4H15.54v-3h5.83Z"
            transform="translate(0 -0.03)"
            fill="#fff" /><path
              d="M21.47,7.86h-6a.46.46,0,0,0-.43.49v1.94a.46.46,0,0,0,.43.49h6a.46.46,0,0,0,.43-.49V8.35A.46.46,0,0,0,21.47,7.86ZM21,9.8H15.85v-1H21Z"
              transform="translate(0 -0.03)"
              fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>